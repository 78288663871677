/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 112번째 로그에서는 컨테이너 보안 책, AWS 오사카 리전 발표, 오픈 소스 시각화 10년 - D3.js에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "컨테이너 보안 책 소개"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=260800309"
  }, "컨테이너 보안, 리즈 라이스 저, 류광 번역 - 알라딘")), "\n"), "\n", React.createElement(_components.h3, null, "인프런 - 도커 컴포즈"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.inflearn.com/course/%EB%8F%84%EC%BB%A4-%EC%BB%B4%ED%8F%AC%EC%A6%88"
  }, "도커 쓸 땐 필수! 도커 컴포즈 - 인프런")), "\n"), "\n", React.createElement(_components.h3, null, "AWS 오사카 리전 발표"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/news--aws-osaka-region-public-open"
  }, "AWS, 오사카 리전(ap-northeast-3) 정식 공개 - 44BITS")), "\n"), "\n", React.createElement(_components.h3, null, "D3.js - 오픈소스 프로젝트 10년 하면서 느낀점"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://observablehq.com/@mbostock/10-years-of-open-source-visualization"
  }, "10 Years of Open-Source Visualization")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/channel/UC6plBAYhKfcq1ZZyUalyWOA"
  }, "ChartKing - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bl.ocks.org/mbostock"
  }, "Mike Bostock’s Blocks - bl.ocks.org")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://flourish.studio/"
  }, "Flourish")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://observablehq.com/"
  }, "Observable")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://observablehq.com/@mbostock?tab=notebooks"
  }, "Mike Bostock / Observable")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
